<template>
  <div class="solve">
    <div class="solve_banner_wraper">
      <div class="solve_banner_container">
        <div class="solve_banner_container_title">厚实产品解决方案</div>
        <div class="solve_banner_container_desc">针对不同政务单位的特性</div>
        <div class="solve_banner_container_detail">打造个性适用的办公产品</div>
        <div class="solve_banner_container_btn">获取你的专属方案</div>
      </div>
    </div>
    <div class="solve_solu_wraper">
      <div class="solve_solu">
        <div class="solve_solu_img">
          <img src="@/assets/images/solve/home/img1.png" alt="" />
        </div>
        <div class="solve_solu_title">专属政务解决方案</div>
        <div class="solve_solu_desc">
          基于厚实软件十年业务积累的丰富经验，为政务单位客户量身定制专属产品与服务
        </div>
        <div class="solve_solu_item">
          <div class="solve_solu_item_img">
            <img src="@/assets/images/solve/home/gzpt.png" alt="" />
            <div class="solve_solu_item_right">
              <div class="solve_solu_item_right_title">
                智慧政务高效工作平台
              </div>
              <div class="solve_solu_item_right_desc">
                告别信息孤岛，构建全连接、一网式工作新模式
              </div>
            </div>
          </div>
          <div class="solve_solu_item_wrapper">
            <div
              class="solve_solu_item_wrapper_li"
              v-for="item in card1"
              :key="item.id"
              :class="{ active: activeIndex === item.id }"
              @click="changeCard(item.id)"
            >
              <div class="solve_solu_item_wrapper_title">{{ item.title }}</div>
              <div class="solve_solu_item_wrapper_desc">
                {{ item.desc }}
              </div>
              <div
                class="solve_solu_item_wrapper_detail"
                @click="goTo(item.url)"
              >
                {{ item.detail }}
              </div>
            </div>
          </div>
          <div class="solve_solu_item_line"></div>
          <div class="solve_solu_item_wrapper">
            <div
              class="solve_solu_item_wrapper_li"
              v-for="item in card2"
              :key="item.id"
              :class="{ active: activeIndex === item.id }"
              @click="changeCard(item.id)"
            >
              <div class="solve_solu_item_wrapper_title">{{ item.title }}</div>
              <div class="solve_solu_item_wrapper_desc">
                {{ item.desc }}
              </div>
              <div
                class="solve_solu_item_wrapper_detail"
                @click="goTo(item.url)"
              >
                {{ item.detail }}
              </div>
            </div>
          </div>
        </div>
        <div class="solve_solu_item">
          <div class="solve_solu_item_img">
            <img src="@/assets/images/solve/home/fwpt.png" alt="" />
            <div class="solve_solu_item_right">
              <div class="solve_solu_item_right_title">
                智慧政务综合服务平台
              </div>
              <div class="solve_solu_item_right_desc">
                构建全链接一网式服务新模式，随时随地通过移动互联网连接党员、群众和企业
              </div>
            </div>
          </div>
          <div class="solve_solu_item_wrapper">
            <div
              class="solve_solu_item_wrapper_li"
              v-for="item in card3"
              :key="item.id"
              :class="{ active: activeIndex === item.id }"
              @click="changeCard(item.id)"
            >
              <div class="solve_solu_item_wrapper_title">{{ item.title }}</div>
              <div class="solve_solu_item_wrapper_desc">
                {{ item.desc }}
              </div>
              <div
                class="solve_solu_item_wrapper_detail"
                @click="goTo(item.url)"
              >
                {{ item.detail }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <solve-footer></solve-footer>
  </div>
</template>

<script>
import SolveFooter from '@/components/SolveFooter'
export default {
  name: '',
  components: {
    SolveFooter
  },
  props: {

  },
  data () {
    return {
      activeIndex: -1,
      card1: [
        {
          id: 0,
          title: '智能办文',
          desc: '办文效率大幅提升，时刻享受智能化带来的便捷',
          detail: '了解详情 >',
          url: '/solve/office'
        },
        {
          id: 1,
          title: '智能会务',
          desc: '会务全流程智能化管理，高效安全更环保',
          detail: '了解详情 >',
          url: '/solve/meeting'
        },
        {
          id: 2,
          title: '互动交流',
          desc: '信息文件一网共享，简便却不失规范',
          detail: '了解详情 >',
          url: '/solve/interact'
        },
        {
          id: 3,
          title: '工作日程',
          desc: '工作不再没规划，智能助手帮你全统筹',
          detail: '了解详情 >',
          url: '/solve/gzrcjjfa'
        }, {
          id: 4,
          title: '行政导航',
          desc: '行政管理“GPS”，监控行政“慢作为”',
          detail: '了解详情 >',
          url: '/solve/xzdhjjfa'
        },
        {
          id: 5,
          title: '移动办公',
          desc: '协同高效与安全并重，随时随地移动办公',
          detail: '了解详情 >'
        }
      ],
      card2: [
        {
          id: 6,
          title: '智慧饭堂',
          desc: '重塑政府用餐模式，做光盘行动的践行者',
          detail: '了解详情 >',
          url: '/solve/zhft'
        },
        {
          id: 7,
          title: '智慧图书',
          desc: '创新全民阅读模式，建设学习型政府',
          detail: '了解详情 >'
        }
      ],
      card3: [
        {
          id: 8,
          title: '综合服务平台',
          desc: '依托移动互联网技术，打造便民服务型政府',
          detail: '了解详情 >'
        },
        {
          id: 9,
          title: '教育招生',
          desc: '从报名到确认就读，全流程智能化管理',
          detail: '了解详情 >'
        },
        {
          id: 10,
          title: 'AI监测',
          desc: '从看得清到看得懂，变被动监控为主动识别',
          detail: '了解详情 >',
          url: '/solve/servey'
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    changeCard (id) {
      this.activeIndex = id
    },
    goTo (url) {
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
